<template>
  <div>
    <!-- main-slider -->
    <section class="w3l-main-slider" id="home">
      <div class="companies20-content">
        <carousel
          v-if="
            headline[0] &&
            headline[0].background &&
            headline[0].background.length
          "
          :items="1"
          :nav="false"
          :autoplay="true"
        >
          <div class="item" v-for="(hl, index) in headline" :key="index">
            <li>
              <div
                v-bind:style="{
                  'background-image':
                    'url(' +
                    defaultBackground() +
                    `/images/service/` +
                    hl.id_service +
                    `/` +
                    hl.background +
                    ')',
                  ' background-size': cover,
                  'min-height': 'calc(100vh - 140px)',
                  position: relative,
                  'z-index': 0,
                  display: grid,
                  'align-items': center,
                  padding: '3em 0',
                }"
                class="slider-info bg bg2"
              >
                <div class="banner-info">
                  <div class="container">
                    <div class="banner-info-bg">
                      <h6>
                        {{
                          hl.kategori_rel[0].nama_kategori_service | capitalize
                        }}
                      </h6>
                      <h5>{{ hl.nama_service | capitalize }}</h5>
                      <p
                        class="mt-md-4 mt-3"
                        v-html="hl.keterangan_singkat"
                      ></p>
                      <router-link
                        class="btn btn-style btn-primary mt-sm-5 mt-4 mr-2"
                        :to="{
                          name: 'detailservice',
                          params: { id_service: hl.id_service },
                        }"
                      >
                        Read More</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </div>
        </carousel>
      </div>
    </section>
    <!-- /main-slider -->
    <!-- about page about section -->
    <!-- <section class="w3l-aboutblock1" id="about">
      <div class="midd-w3 py-5">
        <div class="container py-lg-5 py-md-3 py-2">
          <div class="row">
            <div class="col-lg-6 mb-lg-0 mb-md-5 mb-5 align-self">
              <h3 class="title-big mx-0">
                Best IT Solution Service From Experienced Engineer.
              </h3>
              <p class="mt-md-4 mt-3">
                Lorem ipsum viverra feugiat. Pellen tesque libero ut justo, sed
                ultrices in ligula. Semper at tempufddfel. Lorem ipsum dolor sit
                amet elit. Non quae, fugiat nihil ad.
              </p>
              <p class="mt-3">
                Pellen tesque libero ut justo, ultrices in ligula. Semper at.
                Lorem init ipsum dolor sit amet elit. Dolor ipsum non velit,
                culpa! Pellen tesque libero ut justo, ultrices in ligula amet
                dolor sit init dolor sit, amet elit. Dolor ipsum non velit,
                culpa! elit ut et.
              </p>
              <a
                href="#features"
                class="btn btn-primary btn-style mt-md-5 mt-4 mr-2"
                >Explore features</a
              >
              <a
                href="#view"
                class="btn btn-outline-primary btn-style mt-md-5 mt-4"
              >
                View all</a
              >
            </div>
            <div class="col-lg-6">
              <div class="position-relative">
                <img
                  src="../assets/images/about.jpg"
                  alt=""
                  class="radius-image img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <section class="w3l-services-block-3 py-5" id="services">
      <div class="features-main py-md-3">
        <div class="container">
          <div class="header-section text-center mx-auto">
            <h3 class="title-big">OUR SERVICES</h3>
            <h4 class="head">
              The complex laws of Indonesia may be a little overwhelming for
              you, ​we are here to guide you in the right direction in the
              following departments.
              <!-- {{ katservice[0].nama_kategori_service }} -->
            </h4>
          </div>
          <div class="row features mt-lg-4">
            <div
              class="col-lg-4 col-md-6 feature-grid"
              v-for="(ks, index) in katservice"
              :key="index"
            >
              <span>
                <div class="feature-body service1">
                  <div class="feature-img">
                    <!-- <span class="fa fa-sun-o" aria-hidden="true"></span> -->
                    <span>
                      <img
                        width="114px"
                        :src="
                          defaultBackground() +
                          '/images/kategori_service/' +
                          ks.id_kategoriservice +
                          '/' +
                          ks.logo
                        "
                    /></span>
                  </div>
                  <div class="feature-info mt-4">
                    <h3 class="feature-titel mb-3">
                      {{ ks.nama_kategori_service | capitalize }}
                    </h3>
                    <!-- <p class="feature-text">
                      Lorem ipsum dolor sit amet elit et. Debitis nam, minima
                      iste ipsum.
                    </p> -->
                    <span v-if="ks.kontak == 'Y'">
                      <a
                        target="_blank"
                        :href="
                          ('https://api.whatsapp.com/send?phone=' +
                            pemiliks[0].telp_01 +
                            '&text= Hello, I want to apply for a ' +
                            ks.nama_kategori_service)
                            | capitalize
                        "
                        class="
                          btn btn-outline-info
                          d-none d-lg-block
                          btn-style
                          mr-3
                        "
                      >
                        Contact Us</a
                      >
                    </span>
                    <span v-else>
                      <router-link
                        :to="{
                          name: 'kategoriservice',
                          params: {
                            id_kategoriservice: ks.id_kategoriservice,
                          },
                        }"
                        class="
                          btn btn-outline-info
                          d-none d-lg-block
                          btn-style
                          mr-3
                        "
                      >
                        Read More</router-link
                      >
                    </span>
                  </div>
                </div>
              </span>
            </div>
            <!-- <div class="col-lg-4 col-md-6 feature-grid">
              <a href="#url">
                <div class="feature-body service2">
                  <div class="feature-img">
                    <span
                      class="fa fa-wrench icon-fea"
                      aria-hidden="true"
                    ></span>
                  </div>
                  <div class="feature-info mt-4">
                    <h3 class="feature-titel mb-3">Deep Expertise</h3>
                    <p class="feature-text">
                      Lorem ipsum dolor sit amet elit et. Debitis nam, minima
                      iste ipsum.
                    </p>
                    <a href="#read" class="read mt-4 d-block"> Read More</a>
                  </div>
                </div>
              </a>
            </div> -->
            <!-- <div class="col-lg-4 col-md-6 feature-grid">
              <a href="#url">
                <div class="feature-body service3">
                  <div class="feature-img">
                    <span class="fa fa-flask" aria-hidden="true"></span>
                  </div>
                  <div class="feature-info mt-4">
                    <h3 class="feature-titel mb-3">Software development</h3>
                    <p class="feature-text">
                      Lorem ipsum dolor sit amet elit et. Debitis nam, minima
                      iste ipsum.
                    </p>
                    <a href="#read" class="read mt-4 d-block"> Read More</a>
                  </div>
                </div>
              </a>
            </div> -->
          </div>
        </div>
      </div>
    </section>
    <div class="w3l-servicesblock2" id="services">
      <section id="grids5-block" class="py-5">
        <div class="container py-lg-5 py-md-4 py-2">
          <h3 class="title-big text-center">
            Order Visa with a Payment Gateway System
          </h3>
          <div class="row mt-lg-5 mt-4">
            <div
              class="col-lg-4 col-md-6"
              v-for="(p, index) in produks"
              :key="index"
            >
              <div class="grids5-info">
                <a class="d-block zoom"
                  ><img
                    :src="
                      defaultBackground() +
                      '/images/produk/' +
                      p.id_produk +
                      '/' +
                      p.photo_produk
                    "
                    alt=""
                    class="img-fluid-customs service-image"
                /></a>
                <div class="blog-info">
                  <span class="fa fa-id-card"></span>
                  <h4>
                    <a>{{ p.permitstay }}</a>
                  </h4>
                </div>
                <div
                  style="
                    padding: 30px 25px;
                    background: var(--bg-color);
                    border: 1px solid var(--border-color-light);
                    border-bottom-left-radius: var(--border-radius);
                    border-bottom-right-radius: var(--border-radius);
                    grid-template-columns: auto 1fr;
                    grid-gap: 25px;
                    align-items: center;
                    box-shadow: 0px 9px 24px 5px rgba(0, 0, 0, 0.04);
                  "
                >
                  <ul class="list-group mb-4">
                    <li class="list-group-item">
                      Remark: <strong>{{ p.remark | capitalize }}</strong>
                    </li>
                    <li class="list-group-item">
                      Los: <strong>{{ p.los | capitalize }}</strong>
                    </li>
                    <li class="list-group-item">
                      IDR : <strong> {{ p.idr | currency }}</strong>
                    </li>
                    <li v-if="p.usd != 0" class="list-group-item">
                      USD : <strong> {{ p.usd | dollar_currency }}</strong>
                    </li>
                  </ul>
                </div>
                <!-- <router-link
                  :to="{
                    name: 'chartproduct',
                    params: {
                      id_produk: p.id_produk,
                    },
                  }"
                  style="
                    padding: 12px 35px;
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 600;
                    transition: 0.3s ease-in;
                    border-radius: var(--border-radius);
                  "
                  type="button"
                  class="btn btn-primary btn-block animate-up-1"
                  tabindex="0"
                >
                  ORDER</router-link
                > -->

                <button
                  style="
                    padding: 12px 35px;
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 600;
                    transition: 0.3s ease-in;
                    border-radius: var(--border-radius);
                  "
                  type="submit"
                  class="btn btn-primary btn-block animate-up-1"
                  tabindex="0"
                  @click="createData(p.id_produk, index)"
                >
                  ORDER
                </button>
                <br />
              </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 mt-md-0 mt-5">
              <div class="grids5-info">
                <a href="#service" class="d-block zoom"
                  ><img
                    src="../assets/images/s4.jpg"
                    alt=""
                    class="img-fluid service-image"
                /></a>
                <div class="blog-info">
                  <span class="fa fa-cogs"></span>
                  <h4>
                    <a href="#service">Digital Product Development</a>
                  </h4>
                </div>
              </div>
            </div> -->
            <!-- <div class="col-lg-4 col-md-6 mt-lg-0 mt-5">
              <div class="grids5-info">
                <a href="#service" class="d-block zoom"
                  ><img
                    src="../assets/images/s3.jpg"
                    alt=""
                    class="img-fluid service-image"
                /></a>
                <div class="blog-info">
                  <span class="fa fa-line-chart"></span>
                  <h4>
                    <a href="#service">Business Software Development</a>
                  </h4>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </section>
    </div>
    <!--  //services section -->
    <!-- <section class="w3l-products w3l-faq-block py-5" id="projects">
      <div class="container py-lg-5 py-md-4 py-2">
        <div class="row">
          <div class="col-lg-6 mx-auto">
            <h3 class="title-big mb-md-5 mb-4 mw-100">
              Need a Beter Work? We are here to IT Solution with 30 years of
              experience
            </h3>

            <div id="parentHorizontalTab">
              <ul class="resp-tabs-list hor_1 mb-2">
                <li class="resp-tab-item hor_1 resp-tab-active" role="tab">
                  What we do
                </li>
                <li class="resp-tab-item hor_1" role="tab">Our Mission</li>
                <li class="resp-tab-item hor_1" role="tab">Social impact</li>
                <div class="clear"></div>
              </ul>
              <div class="resp-tabs-container hor_1">
                <div
                  class="
                    products-content
                    resp-tab-content
                    hor_1
                    resp-tab-content-active
                  "
                  aria-labelledby="hor_1_tab_item-0"
                >
                  <h4 class="head">
                    To believe that the smart looking website is the first
                    impression all over.
                  </h4>
                  <p>
                    This Our History to a tendency to believe that the smart
                    looking website is the first impression. Lorem dolor sit
                    amet, elit!
                  </p>
                  <ul class="w3l-right-book mt-4">
                    <li>
                      <span class="fa fa-check" aria-hidden="true"></span
                      >Leading private equity firms
                    </li>
                  </ul>
                </div>
                <div
                  class="products-content resp-tab-content hor_1"
                  aria-labelledby="hor_1_tab_item-1"
                >
                  <h4 class="head">
                    This Our History to a tendency to believe that the smart
                    looking website is the first impression.
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, elit. Id ab commodi impedit
                    magnam sint voluptates. Minima velit expedita maiores, sit
                    at in!
                  </p>
                  <ul class="w3l-right-book mt-4">
                    <li>
                      <span class="fa fa-check" aria-hidden="true"></span
                      >Helping Nonprofit organizations
                    </li>
                    <li>
                      <span class="fa fa-check" aria-hidden="true"></span
                      >Leading private equity firms
                    </li>
                  </ul>
                </div>
                <div class="products-content">
                  <h4 class="head">
                    This Our History to a tendency to believe thes that smart
                    looking website is the first impression.
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, elit. Id ab commodi impedit
                    magnam sint voluptates. Minima velit expedita maiores, sit
                    at in!
                  </p>
                  <ul class="w3l-right-book mt-4">
                    <li>
                      <span class="fa fa-check" aria-hidden="true"></span>Always
                      Fast and friendly support
                    </li>
                    <li>
                      <span class="fa fa-check" aria-hidden="true"></span
                      >Experienced Professional Team
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 pr-lg-5 mt-lg-0 mt-sm-5 mt-4">
            <div class="accordion">
              <div class="accordion-item">
                <button id="accordion-button-1" aria-expanded="true">
                  <span class="accordion-title"
                    >How much does a static website cost?</span
                  ><span class="icon" aria-hidden="true"></span>
                </button>
                <div class="accordion-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor.
                  </p>
                </div>
              </div>
              <div class="accordion-item">
                <button id="accordion-button-2" aria-expanded="false">
                  <span class="accordion-title"
                    >How to choose a best web template?</span
                  ><span class="icon" aria-hidden="true"></span>
                </button>
                <div class="accordion-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Elementum sagittis vitae et leo duis ut. Ut pretium.
                  </p>
                </div>
              </div>
              <div class="accordion-item">
                <button id="accordion-button-3" aria-expanded="false">
                  <span class="accordion-title"
                    >How to download a template?</span
                  ><span class="icon" aria-hidden="true"></span>
                </button>
                <div class="accordion-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor.
                  </p>
                </div>
              </div>
              <div class="accordion-item">
                <button id="accordion-button-4" aria-expanded="false">
                  <span class="accordion-title"
                    >Why should i choose a free website?</span
                  ><span class="icon" aria-hidden="true"></span>
                </button>
                <div class="accordion-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Elementum sagittis vitae et leo duis ut. Ut potenti.
                  </p>
                </div>
              </div>
              <div class="accordion-item">
                <button id="accordion-button-4" aria-expanded="false">
                  <span class="accordion-title"
                    >Why should i choose a free website?</span
                  ><span class="icon" aria-hidden="true"></span>
                </button>
                <div class="accordion-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Elementum sagittis vitae et leo duis ut. Ut potenti.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!--  services section -->
    <div class="w3l-servicesblock2" id="process">
      <!-- <section id="grids5-block" class="py-5">
        <div class="container py-lg-5 py-md-4 py-2">
          <h3 class="title-big text-center">
            Come for Visit to See our Work Process
          </h3>
          <div class="row mt-lg-5 mt-4">
            <div class="col-lg-3 col-sm-6">
              <div class="grids5-info">
                <a class="blog-image d-block zoom"
                  ><img
                    src="../assets/images/p1.jpg"
                    alt=""
                    class="img-fluid radius-image"
                  />
                  <div class="image-overlay">
                    <h4>Ease of making visa</h4>
                    <p>From home you can apply for a visa.</p>
                  </div>
                  <div class="iconic-plus">
                    <span class="fa fa-plus"></span>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 mt-md-0 mt-4">
              <div class="grids5-info">
                <a class="blog-image d-block zoom"
                  ><img
                    src="../assets/images/p2.jpg"
                    alt=""
                    class="img-fluid radius-image"
                  />
                  <div class="image-overlay">
                    <h4>Get the best price</h4>
                    <p>
                      Expat Service Bali provides the best prices on visa
                      services.
                    </p>
                  </div>
                  <div class="iconic-plus">
                    <span class="fa fa-plus"></span>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 mt-lg-0 mt-4">
              <div class="grids5-info">
                <a class="blog-image d-block zoom"
                  ><img
                    src="../assets/images/p3.jpg"
                    alt=""
                    class="img-fluid radius-image"
                  />
                  <div class="image-overlay">
                    <h4>
                      Don't let the immigration officials abuse you in the
                      payment of huge bribe!
                    </h4>
                    <p>
                      Don't let anyone persuade you that the short overstay is a
                      catastrophe or a criminal act.
                    </p>
                  </div>
                  <div class="iconic-plus">
                    <span class="fa fa-plus"></span>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 mt-lg-0 mt-4">
              <div class="grids5-info">
                <a class="blog-image d-block zoom"
                  ><img
                    src="../assets/images/p4.jpg"
                    alt=""
                    class="img-fluid radius-image"
                  />
                  <div class="image-overlay">
                    <h4>
                      Waiting and trying to avoid the issue is never a good
                      option.
                    </h4>
                    <p>
                      The sooner you act to begin to resolve the situation, the
                      better!
                    </p>
                  </div>
                  <div class="iconic-plus btn btn-primary btn-block">
                    <span class="fa-solid fa-plus"></span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> -->
    </div>
    <section class="w3l-bottom1 py-5">
      <div class="container py-md-5 py-sm-4 py-2">
        <div class="row">
          <div class="col-lg-7">
            <div class="bottom-info">
              <div class="header-section">
                <h3 class="title-big mx-0">Here to empower the community</h3>
                <!-- <p class="mt-3 pr-lg-5">
                  Lorem ipsum dolor sit amet elit. Velit beatae rem ullam dolore
                  nisi esse quasi. Integer sit amet. Lorem ipsum dolor sit amet
                  elit.
                </p> -->
              </div>
            </div>
          </div>
          <div class="col-lg-5 align-self text-lg-right">
            <!-- <a
              href="#learn"
              class="btn btn-style btn-outline-dark mt-lg-0 mt-md-5 mt-4 mr-2"
              >Learn More
            </a> -->
            <a
              :href="
                ('https://api.whatsapp.com/send?phone=' +
                  pemiliks[0].telp_01 +
                  '&text= Hello expat service bali')
                  | capitalize
              "
              class="btn btn-style btn-primary mt-lg-0 mt-md-5 mt-4"
              >Contact us</a
            >
          </div>
        </div>
      </div>
    </section>

    <!--  News section -->
    <!-- <div class="w3l-news text-11" id="homeblog">
      <section id="grids5-block" class="py-5">
        <div class="container py-lg-5 py-md-4 py-2">
          <h3 class="title-big text-center">
            Our Great Idea For<br />
            IT News
          </h3>
          <div class="row mt-lg-5 mt-4 text11-content">
            <div class="col-lg-4 col-md-6 mt-md-0 mt-sm-4">
              <div class="grids5-info">
                <a href="#blog-single" class="blog-image d-block zoom"
                  ><img
                    src="../assets/images/blog1.jpg"
                    alt=""
                    class="img-fluid news-image"
                  />
                  <div class="image-overlay">
                    <span class="fa fa-plus"></span>
                  </div>
                </a>
                <div class="blog-info card-body blog-details">
                  <h6 class="">Jan 15, 2021.</h6>
                  <a href="#category" class="post">Business, Case study</a>

                  <a href="#blog-single" class="blog-desc"
                    >Designers Checklist for Every UX Project they Do.</a
                  >
                  <a href="#blog-single" class="read"
                    >Read more <span class="fa fa-arrow-right"></span
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-md-0 mt-5">
              <div class="grids5-info">
                <a href="#blog-single" class="blog-image d-block zoom"
                  ><img
                    src="../assets/images/blog6.jpg"
                    alt=""
                    class="img-fluid news-image"
                  />
                  <div class="image-overlay">
                    <span class="fa fa-plus"></span>
                  </div>
                </a>
                <div class="blog-info card-body blog-details">
                  <h6 class="">Jan 15, 2021.</h6>
                  <a href="#category" class="post">Business, Case study</a>

                  <a href="#blog-single" class="blog-desc"
                    >Designers Checklist for Every UX Project they Do.</a
                  >
                  <a href="#blog-single" class="read"
                    >Read more <span class="fa fa-arrow-right"></span
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-lg-0 mt-5">
              <div class="grids5-info">
                <a href="#blog-single" class="blog-image d-block zoom"
                  ><img
                    src="../assets/images/blog3.jpg"
                    alt=""
                    class="img-fluid news-image"
                  />
                  <div class="image-overlay">
                    <span class="fa fa-plus"></span>
                  </div>
                </a>
                <div class="blog-info card-body blog-details">
                  <h6 class="">Jan 15, 2021.</h6>
                  <a href="#category" class="post">Business, Case study</a>
                  <a href="#blog-single" class="blog-desc"
                    >Designers Checklist for Every UX Project they Do.</a
                  >
                  <a href="#blog-single" class="read"
                    >Read more <span class="fa fa-arrow-right"></span
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div> -->
    <!--  //News section -->
    <!-- customers Section -->
    <section class="w3l-customers py-md-5 py-4" id="clients">
      <div class="container">
        <div
          class="row"
          style="
            font-family: 'Avenir', Helvetica, Arial, sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-align: center;
            color: #ffffff;
          "
        >
          <div class="col-md-12">
            <!-- <center>
              <div
                style="
                  font-size: 36px;
                  line-height: 45px;
                  font-weight: 700;
                  max-width: 570px;
                  color: #ffffff;
                "
              >
                Clients
              </div>
            </center> -->
            <!-- <carousel class="owl-three owl-carousel owl-theme logo-view"> -->
            <center>
              <carousel
                :items="2"
                :nav="false"
                :autoplay="true"
                :dots="false"
                class="owl-three"
              >
                <!-- <carousel class="owl-three owl-carousel owl-theme logo-view"> -->
                <div class="item">
                  <img
                    :src="
                      defaultBackground() + '/images/clients/expat_camilla.jpg'
                    "
                    alt="company-logo"
                    class="img-fluid"
                  />
                </div>
                <div class="item">
                  <img
                    :src="
                      defaultBackground() +
                      '/images/clients/expat_camilla_beach.png'
                    "
                    alt="company-logo"
                    class="img-fluid"
                  />
                </div>
                <div class="item">
                  <img
                    :src="
                      defaultBackground() + '/images/clients/expat_maviba.png'
                    "
                    alt="company-logo"
                    class="img-fluid"
                  />
                </div>
                <div class="item">
                  <img
                    :src="
                      defaultBackground() + '/images/clients/expat_nalu.png'
                    "
                    alt="company-logo"
                    class="img-fluid"
                  />
                </div>
              </carousel>
            </center>
          </div>
        </div>
      </div>
    </section>
    <!-- //customers Section -->
  </div>
</template>

<script>
import Carousel from "vue-owl-carousel";
// import $ from "jquery"; //import jQuery
// import func from "vue-editor-bridge";

export default {
  name: "HomeView",
  components: { Carousel },

  data() {
    return {
      katservice: [],
      headline: [],
      pemiliks: [],
      produks: [],
    };
  },
  mounted() {
    this.$isLoading(true);
    this.getKategoriServive();
    this.getHeadline();
    this.getPemilik();
    this.getProdukAll();
    setTimeout(() => {
      this.$isLoading(false);
    }, 4000);

    // this.carouselSliderWithNav();
  },
  filters: {
    capitalize: function (data) {
      var capitalized = [];
      data.split(" ").forEach((word) => {
        capitalized.push(
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      });
      return capitalized.join(" ");
    },
  },
  methods: {
    createData(e, index) {
      this.id_produk = this.produks[index].id_produk;
      this.errors = [];

      //state token
      localStorage.getItem("token");
      let uricheck = process.env.VUE_APP_ROOT_API + "/api/u/produk/cekorder";

      this.axios
        .post(uricheck, {
          id_produk: this.id_produk,
        })
        .then((response) => {
          this.msg = response.data.checkorder;
          if (this.msg == "false") {
            let uri = process.env.VUE_APP_ROOT_API + "/api/u/produk/store";
            this.axios
              .post(uri, {
                id_produk: this.id_produk,
              })
              .then((_) => {
                // this.$isLoading(false);
                // if (response.statusText == 'Created') {
                this.$swal
                  .fire({
                    title: "Success",
                    text: "Order created successfully",
                    icon: "success",
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 1000,
                  })
                  .then((v) => {
                    if (v.isDismissed) {
                      this.$router.push({
                        name: "transaction",
                      });
                    }
                  });
              })
              .catch((error) => {
                console.error(error);
                // this.$isLoading(false);
                this.$swal.fire({
                  title: "Error",
                  text: "Something wrong, can't create order!",
                  icon: "error",
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 3000,
                });
              });
          } else {
            // this.$isLoading(false);
            this.$swal.fire({
              title: "Maaf",
              text: "Anda sudah melakukan order",
              icon: "error",
              showCancelButton: false,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          // this.$isLoading(false);
          this.$swal.fire({
            title: "Error",
            text: "Something wrong, can't create order!",
            icon: "error",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },

    defaultBackground() {
      let imageDefault = process.env.VUE_APP_ROOT_API;
      return imageDefault;
    },

    getPemilik() {
      //state token
      localStorage.getItem("token");
      let url = process.env.VUE_APP_ROOT_API + "/api/u/pemilik";
      return this.axios.get(url).then((response) => {
        this.pemiliks = response.data.pemilik;
      });
    },

    getKategoriServive() {
      //state token
      localStorage.getItem("token");
      let url = process.env.VUE_APP_ROOT_API + "/api/u/kategoriservice";
      return this.axios.get(url).then((response) => {
        this.katservice = response.data.kategoriservice;
      });
    },

    getProdukAll() {
      //state token
      localStorage.getItem("token");
      let url = process.env.VUE_APP_ROOT_API + "/api/u/produk";
      return this.axios.get(url).then((response) => {
        this.produks = response.data.produk;
      });
    },

    getHeadline() {
      //state token
      localStorage.getItem("token");
      let url = process.env.VUE_APP_ROOT_API + "/api/u/headline";
      return this.axios.get(url).then((response) => {
        this.headline = response.data.service;
      });
    },
  },
};
</script>
<style>
.img-fluid-customs {
  max-width: 100%;
  height: auto;
}
</style>


