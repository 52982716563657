import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import "../assets/css/owl.carousel.css";
import "../assets/css/style-starter.css";
// import "../assets/js/jquery-3.3.1.min.js";
import "../assets/js/bootstrap.min.js";
// import "../assets/js/easyResponsiveTabs.js";
// import "../assets/js/jquery-1.9.1.min.js";

// import VueCarousel from "vue-carousel";
// import $ from "jquery";

import VueSimpleAccordion from "vue-simple-accordion";
import "vue-simple-accordion/dist/vue-simple-accordion.css";
Vue.use(VueSimpleAccordion);

import VueCurrencyFilter from "vue-currency-filter";

const currencyFilterPluginOptions = [
  {
    symbol: "Rp.",
    thousandsSeparator: ".",
    fractionCount: 0,
    fractionSeparator: ",",
    symbolPosition: "front",
    symbolSpacing: true,
  },
  {
    name: "dollar_currency",
    symbol: "$",
    thousandsSeparator: ",",
    fractionCount: 2,
    fractionSeparator: ".",
    symbolPosition: "front",
    symbolSpacing: true,
    avoidEmptyDecimals: "",
  },
];

Vue.use(VueCurrencyFilter, currencyFilterPluginOptions);
import VueSweetalert2 from "vue-sweetalert2";
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

// Tanggal format tgl-bulan-tahun
import moment from "moment";

Vue.filter("date", function (date) {
  return moment(date).format("D MMM Y");
});

//tambahan tanggal
Vue.filter("bulans", function (bulan) {
  const bulans = String(bulan);
  // 10000
  if (bulans == 1) {
    return "Januari";
  }
  if (bulans == 2) {
    return "February";
  }
  if (bulans == 3) {
    return "Maret";
  }
  if (bulans == 4) {
    return "April";
  }
  if (bulans == 5) {
    return "Mei";
  }
  if (bulans == 6) {
    return "Juni";
  }
  if (bulans == 7) {
    return "Juli";
  }
  if (bulans == 8) {
    return "Agustus";
  }
  if (bulans == 9) {
    return "September";
  }
  if (bulans == 10) {
    return "Oktober";
  }
  if (bulans == 11) {
    return "November";
  }
  if (bulans == 12) {
    return "Desember";
  }
});

Vue.filter("date", function (date) {
  return moment(date).format("D MMM Y");
});

Vue.filter("tgl", function (tgl) {
  return moment(tgl).format("D");
});

Vue.filter("bln", function (bln) {
  return moment(bln).format("MMM");
});

Vue.filter("thn", function (thn) {
  return moment(thn).format("Y");
});

Vue.filter("time", function (time) {
  return moment(time).format("h:s");
});

// Vue.use($);
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
Vue.use(VueRouter);

// Tambah request interceptor untuk ongoing request
axios.interceptors.request.use(
  function (config) {
    // Ambil token
    const token = localStorage.getItem("token");

    // Jika access token ada, set authorization header tiap request
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/staticpage/:id_halamanstatis",
    name: "staticpage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "kategoriservice" */ "../views/StaticPageView.vue"
      ),
  },
  {
    path: "/detailservice/:id_service",
    name: "detailservice",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "kategoriservice" */ "../views/DetailServiceView.vue"
      ),
  },
  {
    path: "/kategoriservice/:id_kategoriservice",
    name: "kategoriservice",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "kategoriservice" */ "../views/KategoriServiceView.vue"
      ),
  },

  {
    path: "/transaction",
    name: "transaction",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "transaction" */ "../views/TransaksiView.vue"
      ),
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },

  {
    path: "/dashboard",
    name: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/DashboardView.vue"),
  },

  {
    path: "/contactus",
    name: "contactus",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "contactus" */ "../views/ContactView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
