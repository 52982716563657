<template>
  <div id="app">
    <!-- top header -->
    <section class="w3l-top-header">
      <div class="container">
        <div class="top-header-2">
          <div class="d-grid main-top">
            <div class="top-headers">
              <ul class="left-info">
                <li>
                  <span class="fa fa-phone"></span
                  ><a :href="'tel:' + pemiliks[0].telp_01"
                    >&nbsp; {{ pemiliks[0].telp_01 }}</a
                  >
                </li>
                <!-- <li class="">
                  <a href="#help" class="d-md-block d-none">Help Center</a>
                </li> -->
                <li>
                  <a
                    :href="'mailto:' + pemiliks[0].email"
                    class="d-md-block d-none"
                    >{{ pemiliks[0].email.toLowerCase().trim() }}</a
                  >
                </li>
              </ul>
            </div>
            <div class="top-headers">
              <ul>
                <li>
                  <a href="https://www.facebook.com/expatservicebali"
                    ><i class="fa-brands fa-facebook-f"></i
                  ></a>
                </li>
                <li>
                  <a href=""><i class="fa-brands fa-twitter"></i></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/internationalexpatservice"
                    ><i class="fa-brands fa-instagram"></i
                  ></a>
                </li>
                <li class="mr-0">
                  <a href=""><i class="fa-brands fa-linkedin"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- //top header -->
    <header id="site-header" class="fixed-top">
      <section class="w3l-header-4">
        <div class="container">
          <nav class="navbar navbar-expand-lg navbar-light">
            <h1>
              <a class="navbar-brand" :href="'/'">
                <img width="40px" :src="showLogo()" />
                <!-- {{ pemiliks[0].nama_perusahaan | capitalize }} -->
              </a>
            </h1>
            <button
              class="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="fa icon-expand fa-bars"></span>
              <span class="fa icon-close fa-times"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active">
                  <a class="nav-link" href="/">Home </a>
                </li>
                <li
                  class="nav-item dropdown @@blog__active"
                  v-for="(mn, index) in menus"
                  :key="index"
                >
                  <div v-if="mn.submenus.length > 0">
                    <a
                      class="nav-link dropdown-toggle"
                      @click="loadKategoriService(mn.link)"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      bis_skin_checked="1"
                    >
                      {{ mn.nama_menu | capitalize }}
                      <span class="fa fa-angle-down"></span>
                    </a>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                      bis_skin_checked="1"
                    >
                      <span v-for="(sb, index) in mn.submenus" :key="index">
                        <router-link
                          class="dropdown-item @@b__active"
                          :to="{
                            name: 'staticpage',
                            params: { id_halamanstatis: sb.link },
                          }"
                          bis_skin_checked="1"
                          >{{ sb.nama_menu | capitalize }}</router-link
                        >
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <a :href="'/kategoriservice/' + mn.link" class="nav-link">{{
                      mn.nama_menu | capitalize
                    }}</a>
                  </div>
                </li>

                <!-- <li
                  class="nav-item dropdown @@blog__active"
                  v-if="datamembercount == 'success'"
                >
                  <div>
                    <a
                      class="nav-link dropdown-toggle search-search"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      bis_skin_checked="1"
                    >
                      <div>
                        <i class="fa fa-user" aria-hidden="false"></i>
                      </div>
                    </a>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                      bis_skin_checked="1"
                    >
                      <router-link
                        class="dropdown-item @@b__active"
                        bis_skin_checked="1"
                        :to="{
                          name: 'dashboard',
                        }"
                        >Dashboard</router-link
                      >
                      <a
                        @click.prevent="getLogout()"
                        class="dropdown-item @@b__active"
                        bis_skin_checked="1"
                        >Logout</a
                      >
                    </div>
                  </div>
                </li> -->
              </ul>

              <ul class="navbar-nav search-right mt-lg-0 mt-2">
                <!-- <li class="nav-item mr-2">
                  <div class="container">
                    <div class="row justify-content-md-center">
                      <div class="" v-if="jumlahtransaksis > 0">
                        <router-link
                          :to="{
                            name: 'transaction',
                          }"
                          class="btn search-search"
                        >
                          <span>
                            <i
                              class="fa fa-shopping-cart"
                              aria-hidden="false"
                            ></i>
                            <span
                              style="color: #ffffff"
                              class="
                                position-absolute
                                start-100
                                translate-middle
                                badge
                                rounded-pill
                                bg-success
                              "
                            >
                              {{ jumlahtransaksis }}
                            </span>
                          </span>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </li> -->
                <li
                  class="nav-item dropdown @@blog__active padding-left: 10px"
                  v-if="datamembercount == 'success'"
                >
                  <div>
                    <a
                      class="btn btn-primary d-none d-lg-block btn-style mr-3"
                      id="navbarDropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      bis_skin_checked="1"
                    >
                      <div>
                        <i
                          style="color: white"
                          class="fa-solid fa-user-check"
                        ></i>
                      </div>
                    </a>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                      bis_skin_checked="1"
                    >
                      <router-link
                        class="dropdown-item @@b__active"
                        bis_skin_checked="1"
                        :to="{
                          name: 'dashboard',
                        }"
                        >Dashboard</router-link
                      >
                      <a
                        @click.prevent="getLogout()"
                        class="dropdown-item @@b__active"
                        bis_skin_checked="1"
                        >Logout</a
                      >
                    </div>
                  </div>
                </li>
                <li
                  v-if="datamembercount == 'error'"
                  style="padding-left: 10px"
                  class="nav-item"
                >
                  <router-link
                    :to="{
                      name: 'login',
                    }"
                    class="btn btn-primary d-none d-lg-block btn-style mr-3"
                    >Login</router-link
                  >
                </li>
                <!-- -->
              </ul>

              <!-- /search popup -->
            </div>
            <!-- toggle switch for light and dark theme -->
            <div class="mobile-position">
              <nav class="navigation">
                <div class="theme-switch-wrapper">
                  <label class="theme-switch" for="checkbox">
                    <input type="checkbox" id="checkbox" @change="setTheme" />
                    <div class="mode-container">
                      <i class="gg-sun"></i>
                      <i class="gg-moon"></i>
                    </div>
                  </label>
                </div>
              </nav>
            </div>

            <div
              class="mobile-position-button-checkedlogin"
              v-if="datamembercount == 'success'"
            >
              <nav class="navigation">
                <div class="theme-switch-wrapper">
                  <div class="mode-container">
                    <nav class="navbar navbar-expand-lg navbar-light">
                      <button
                        class="navbar-toggler collapsed"
                        type="button"
                        data-toggle="collapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        data-target="#navbarNavLogin"
                        aria-controls="navbarNavLogin"
                      >
                        <span class="fa-solid fa-user-check"></span>
                        <span class="fa-solid fa-user-minus"></span>
                      </button>
                      <div
                        style="  background: var(--bg-color);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid var(--border-color-light);
  padding: 20px;
  border-radius: var(--border-radius);
}"
                        class="collapse"
                        id="navbarNavLogin"
                      >
                        <ul class="navbar-nav">
                          <li class="nav-item dropdown">
                            <router-link
                              class="dropdown-item"
                              bis_skin_checked="1"
                              :to="{
                                name: 'dashboard',
                              }"
                              >Dashboard</router-link
                            >
                            <a
                              @click.prevent="getLogout()"
                              class="dropdown-item @@b__active"
                              bis_skin_checked="1"
                              >Logout</a
                            >
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </nav>
            </div>
            <div
              class="mobile-position-button"
              v-if="datamembercount == 'error'"
            >
              <nav class="navigation">
                <div class="theme-switch-wrapper">
                  <div class="mode-container">
                    <router-link
                      :to="{
                        name: 'login',
                      }"
                    >
                      <button
                        type="button"
                        aria-label="Toggle navigation"
                        class="navbar-toggler collapsed"
                      >
                        <i class="fa-solid fa-user"></i></button
                    ></router-link>
                  </div>
                </div>
              </nav>
            </div>
            <div class="mobile-position-cart" v-if="jumlahtransaksis > 0">
              <router-link
                :to="{
                  name: 'transaction',
                }"
                class="btn search-search"
              >
                <nav class="navigation">
                  <div class="theme-switch-wrapper">
                    <div class="mode-container">
                      <i class="fa-solid fa-cart-shopping fa-lg"></i>
                      <span
                        style="color: #ffffff"
                        class="
                          position-absolute
                          start-100
                          translate-middle
                          badge
                          rounded-pill
                          bg-success
                        "
                      >
                        {{ jumlahtransaksis }}
                      </span>
                    </div>
                  </div>
                </nav>
              </router-link>
            </div>
          </nav>
        </div>
      </section>
    </header>
    <router-view />
    <!-- footer -->
    <section class="w3l-footer-29-main">
      <div class="footer-29 py-5">
        <div class="container py-lg-4">
          <div class="row footer-top-29">
            <div
              class="col-lg-4 col-md-6 col-sm-7 footer-list-29 footer-1 pr-lg-5"
            >
              <div class="footer-logo mb-4">
                <a class="navbar-brand" href="/">
                  <!-- <span class="fa fa-clone"></span> -->
                  <img width="50px" :src="showLogo()" />&nbsp;
                  {{ pemiliks[0].nama_perusahaan.toLowerCase().trim() }}</a
                >
              </div>
              <p>
                Welcome to bali international expat service. Services for making
                a residence visa in Indonesia and all your needs.
              </p>
              <div class="main-social-footer-29 mt-4">
                <a
                  href="https://www.facebook.com/expatservicebali"
                  class="facebook"
                  ><i class="fa-brands fa-facebook-f"></i
                ></a>
                <a href="" class="twitter"
                  ><i class="fa-brands fa-twitter"></i
                ></a>
                <a
                  href="https://www.instagram.com/internationalexpatservice"
                  class="instagram"
                  ><i class="fa-brands fa-instagram"></i
                ></a>
                <a href="" class="linkedin"
                  ><i class="fa-brands fa-linkedin"></i
                ></a>
              </div>
            </div>
            <div
              class="
                col-lg-2 col-md-6 col-sm-5 col-6
                footer-list-29 footer-2
                mt-sm-0 mt-5
              "
            ></div>
            <div
              class="
                col-lg-2 col-md-6 col-sm-5 col-6
                footer-list-29 footer-3
                mt-lg-0 mt-5
              "
            ></div>
            <div
              class="
                col-lg-4 col-md-6 col-sm-7
                footer-list-29 footer-4
                mt-lg-0 mt-5
              "
            >
              <h6 class="footer-title-29">Contact Info</h6>
              <p>
                Address :
                {{ pemiliks[0].alamat_perusahaan.toLowerCase().trim() }}.
              </p>
              <p class="mt-2">
                Phone :
                <a :href="'tel:' + pemiliks[0].telp_01">{{
                  pemiliks[0].telp_01
                }}</a>
              </p>
              <p class="mt-2">
                Email :
                <a :href="'mailto:' + pemiliks[0].email">{{
                  pemiliks[0].email.toLowerCase().trim()
                }}</a>
              </p>
              <!-- <p class="mt-2">
                Support :
                <a href="mailto:info@support.com">info@support.com</a>
              </p> -->
            </div>
          </div>
        </div>
      </div>
      <!-- copyright -->
      <section class="w3l-copyright text-center">
        <div class="container">
          <p class="copy-footer-29">
            © 2022 {{ pemiliks[0].nama_perusahaan }}. All rights reserved.
            Design by
            <a href="https://www.langitinspirasi.co.id" target="_blank">
              CV. Langit Inspirasi</a
            >
          </p>
        </div>

        <!-- move top -->
        <button onclick="topFunction()" id="movetop" title="Go to top">
          &#10548;
        </button>
      </section>
      <!-- //copyright -->
    </section>
    <!-- //footer -->
  </div>
</template>
<!--  <script src="../assets/js/easyResponsiveTabs.js"></script>
<script src="../assets/js/bootstrap.min.js"></script> -->
<!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.2.1/owl.carousel.js"></script>-->
<script>
// import "../assets/js/easyResponsiveTabs.js";

import $ from "jquery"; //import jQuery
// import 'jquery-ui';  //import jQuery UI
// import "bootstrap"; //import Bootstrap
export default {
  components: {},
  data() {
    return {
      darkTheme: false,

      id: this.$route.params.id_kategoriservice,
      menus: [],
      pemiliks: [],
      jumlahtransaksis: [],
      datamembercount: null,
      usdidrapi: [],
    };
  },
  watch: {
    "$route.query.params": {
      deep: true,
      immediate: true,
      handler() {
        this.showJumlahTransaksi();
        this.getCheckLogin();
      },
    },

    // "$route.query.params": {
    //   deep: true,
    //   immediate: true,
    //   handler() {
    //     this.getCheckLogin();
    //   },
    // },
  },
  async mounted() {
    // this.dropdowntoggle();
    this.$isLoading(true);
    // this.getAPIdollar();
    this.getCheckLogin();
    setTimeout(() => {
      this.getMenu();
      this.getPemilik();
      this.showJumlahTransaksi();
      this.$isLoading(false);
    }, 2000);
  },
  filters: {
    capitalize: function (data) {
      var capitalized = [];
      data.split(" ").forEach((word) => {
        capitalized.push(
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      });
      return capitalized.join(" ");
    },
  },
  methods: {
    // async getAPIdollar() {
    //   //state token
    //   localStorage.getItem("token");
    //   let uriAPIusd = process.env.VUE_APP_ROOT_API_LOCAL + "/api/latest.json";
    //   return this.axios
    //     .get(uriAPIusd, {
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //       },
    //     })
    //     .then((response) => {
    //       this.idrdollar = response.data.rates.IDR;
    //     });
    // },
    async getLogout() {
      let uri = process.env.VUE_APP_ROOT_API + "/api/u/logout";
      await this.axios
        .post(uri, {
          token: this.token,
        })
        .then((response) => {
          // this.msg = response.data
          // if (this.msg == "success") {
          localStorage.removeItem("token"); //clear token atau key
          this.$swal.fire({
            title: "Success",
            text: "Logout successfully",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 1000,
          });

          this.$router.push("/");
        });
    },
    getCheckLogin() {
      // window.location.reload();
      //state token
      let url = process.env.VUE_APP_ROOT_API + "/api/u/cekloginmember";
      return this.axios.get(url).then((response) => {
        this.cekloginmember = response.data.cekloginmember;
        this.datamembercount = response.data.datamembercount;
      });
    },

    showJumlahTransaksi() {
      //state token
      localStorage.getItem("token");
      let url = process.env.VUE_APP_ROOT_API + "/api/u/showkeranjangbelanja";
      return this.axios.get(url).then((response) => {
        this.jumlahtransaksis = response.data.jumlahtransaksi;
      });
    },

    getMenu() {
      //state token
      localStorage.getItem("token");
      let url = process.env.VUE_APP_ROOT_API + "/api/u/menu";
      return this.axios.get(url).then((response) => {
        this.menus = response.data.menu;
      });
    },

    getPemilik() {
      //state token
      localStorage.getItem("token");
      let url = process.env.VUE_APP_ROOT_API + "/api/u/pemilik";
      return this.axios.get(url).then((response) => {
        this.pemiliks = response.data.pemilik;
      });
    },

    setTheme(e) {
      this.darkTheme = !this.darkTheme;
      document.documentElement.setAttribute(
        "data-theme",
        this.darkTheme == true ? "dark" : "light"
      );
    },

    showLogo() {
      let imageDefault =
        process.env.VUE_APP_ROOT_API + `/images/logo_perusahaan/logoexpat.png`;
      return imageDefault;
    },

    loadKategoriService(id) {
      console.log(id);
      this.$emit("clicked", id);
    },
  },
};
</script>

