var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"w3l-main-slider",attrs:{"id":"home"}},[_c('div',{staticClass:"companies20-content"},[(
          _vm.headline[0] &&
          _vm.headline[0].background &&
          _vm.headline[0].background.length
        )?_c('carousel',{attrs:{"items":1,"nav":false,"autoplay":true}},_vm._l((_vm.headline),function(hl,index){return _c('div',{key:index,staticClass:"item"},[_c('li',[_c('div',{staticClass:"slider-info bg bg2",style:({
                'background-image':
                  'url(' +
                  _vm.defaultBackground() +
                  "/images/service/" +
                  hl.id_service +
                  "/" +
                  hl.background +
                  ')',
                ' background-size': _vm.cover,
                'min-height': 'calc(100vh - 140px)',
                position: _vm.relative,
                'z-index': 0,
                display: _vm.grid,
                'align-items': _vm.center,
                padding: '3em 0',
              })},[_c('div',{staticClass:"banner-info"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"banner-info-bg"},[_c('h6',[_vm._v(" "+_vm._s(_vm._f("capitalize")(hl.kategori_rel[0].nama_kategori_service))+" ")]),_c('h5',[_vm._v(_vm._s(_vm._f("capitalize")(hl.nama_service)))]),_c('p',{staticClass:"mt-md-4 mt-3",domProps:{"innerHTML":_vm._s(hl.keterangan_singkat)}}),_c('router-link',{staticClass:"btn btn-style btn-primary mt-sm-5 mt-4 mr-2",attrs:{"to":{
                        name: 'detailservice',
                        params: { id_service: hl.id_service },
                      }}},[_vm._v(" Read More")])],1)])])])])])}),0):_vm._e()],1)]),_c('section',{staticClass:"w3l-services-block-3 py-5",attrs:{"id":"services"}},[_c('div',{staticClass:"features-main py-md-3"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row features mt-lg-4"},_vm._l((_vm.katservice),function(ks,index){return _c('div',{key:index,staticClass:"col-lg-4 col-md-6 feature-grid"},[_c('span',[_c('div',{staticClass:"feature-body service1"},[_c('div',{staticClass:"feature-img"},[_c('span',[_c('img',{attrs:{"width":"114px","src":_vm.defaultBackground() +
                        '/images/kategori_service/' +
                        ks.id_kategoriservice +
                        '/' +
                        ks.logo}})])]),_c('div',{staticClass:"feature-info mt-4"},[_c('h3',{staticClass:"feature-titel mb-3"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(ks.nama_kategori_service))+" ")]),(ks.kontak == 'Y')?_c('span',[_c('a',{staticClass:"\n                        btn btn-outline-info\n                        d-none d-lg-block\n                        btn-style\n                        mr-3\n                      ",attrs:{"target":"_blank","href":_vm._f("capitalize")(('https://api.whatsapp.com/send?phone=' +
                          _vm.pemiliks[0].telp_01 +
                          '&text= Hello, I want to apply for a ' +
                          ks.nama_kategori_service))}},[_vm._v(" Contact Us")])]):_c('span',[_c('router-link',{staticClass:"\n                        btn btn-outline-info\n                        d-none d-lg-block\n                        btn-style\n                        mr-3\n                      ",attrs:{"to":{
                        name: 'kategoriservice',
                        params: {
                          id_kategoriservice: ks.id_kategoriservice,
                        },
                      }}},[_vm._v(" Read More")])],1)])])])])}),0)])])]),_c('div',{staticClass:"w3l-servicesblock2",attrs:{"id":"services"}},[_c('section',{staticClass:"py-5",attrs:{"id":"grids5-block"}},[_c('div',{staticClass:"container py-lg-5 py-md-4 py-2"},[_c('h3',{staticClass:"title-big text-center"},[_vm._v(" Order Visa with a Payment Gateway System ")]),_c('div',{staticClass:"row mt-lg-5 mt-4"},_vm._l((_vm.produks),function(p,index){return _c('div',{key:index,staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"grids5-info"},[_c('a',{staticClass:"d-block zoom"},[_c('img',{staticClass:"img-fluid-customs service-image",attrs:{"src":_vm.defaultBackground() +
                    '/images/produk/' +
                    p.id_produk +
                    '/' +
                    p.photo_produk,"alt":""}})]),_c('div',{staticClass:"blog-info"},[_c('span',{staticClass:"fa fa-id-card"}),_c('h4',[_c('a',[_vm._v(_vm._s(p.permitstay))])])]),_c('div',{staticStyle:{"padding":"30px 25px","background":"var(--bg-color)","border":"1px solid var(--border-color-light)","border-bottom-left-radius":"var(--border-radius)","border-bottom-right-radius":"var(--border-radius)","grid-template-columns":"auto 1fr","grid-gap":"25px","align-items":"center","box-shadow":"0px 9px 24px 5px rgba(0, 0, 0, 0.04)"}},[_c('ul',{staticClass:"list-group mb-4"},[_c('li',{staticClass:"list-group-item"},[_vm._v(" Remark: "),_c('strong',[_vm._v(_vm._s(_vm._f("capitalize")(p.remark)))])]),_c('li',{staticClass:"list-group-item"},[_vm._v(" Los: "),_c('strong',[_vm._v(_vm._s(_vm._f("capitalize")(p.los)))])]),_c('li',{staticClass:"list-group-item"},[_vm._v(" IDR : "),_c('strong',[_vm._v(" "+_vm._s(_vm._f("currency")(p.idr)))])]),(p.usd != 0)?_c('li',{staticClass:"list-group-item"},[_vm._v(" USD : "),_c('strong',[_vm._v(" "+_vm._s(_vm._f("dollar_currency")(p.usd)))])]):_vm._e()])]),_c('button',{staticClass:"btn btn-primary btn-block animate-up-1",staticStyle:{"padding":"12px 35px","font-size":"18px","line-height":"30px","font-weight":"600","transition":"0.3s ease-in","border-radius":"var(--border-radius)"},attrs:{"type":"submit","tabindex":"0"},on:{"click":function($event){return _vm.createData(p.id_produk, index)}}},[_vm._v(" ORDER ")]),_c('br')])])}),0)])])]),_c('div',{staticClass:"w3l-servicesblock2",attrs:{"id":"process"}}),_c('section',{staticClass:"w3l-bottom1 py-5"},[_c('div',{staticClass:"container py-md-5 py-sm-4 py-2"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-lg-5 align-self text-lg-right"},[_c('a',{staticClass:"btn btn-style btn-primary mt-lg-0 mt-md-5 mt-4",attrs:{"href":_vm._f("capitalize")(('https://api.whatsapp.com/send?phone=' +
                _vm.pemiliks[0].telp_01 +
                '&text= Hello expat service bali'))}},[_vm._v("Contact us")])])])])]),_c('section',{staticClass:"w3l-customers py-md-5 py-4",attrs:{"id":"clients"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",staticStyle:{"font-family":"'Avenir', Helvetica, Arial, sans-serif","-webkit-font-smoothing":"antialiased","-moz-osx-font-smoothing":"grayscale","text-align":"center","color":"#ffffff"}},[_c('div',{staticClass:"col-md-12"},[_c('center',[_c('carousel',{staticClass:"owl-three",attrs:{"items":2,"nav":false,"autoplay":true,"dots":false}},[_c('div',{staticClass:"item"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.defaultBackground() + '/images/clients/expat_camilla.jpg',"alt":"company-logo"}})]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.defaultBackground() +
                    '/images/clients/expat_camilla_beach.png',"alt":"company-logo"}})]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.defaultBackground() + '/images/clients/expat_maviba.png',"alt":"company-logo"}})]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.defaultBackground() + '/images/clients/expat_nalu.png',"alt":"company-logo"}})])])],1)],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-section text-center mx-auto"},[_c('h3',{staticClass:"title-big"},[_vm._v("OUR SERVICES")]),_c('h4',{staticClass:"head"},[_vm._v(" The complex laws of Indonesia may be a little overwhelming for you, ​we are here to guide you in the right direction in the following departments. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-7"},[_c('div',{staticClass:"bottom-info"},[_c('div',{staticClass:"header-section"},[_c('h3',{staticClass:"title-big mx-0"},[_vm._v("Here to empower the community")])])])])}]

export { render, staticRenderFns }