import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import loading from "vuejs-loading-screen";

Vue.use(loading, {
  bg: "rgba(120, 134, 139, 0.8)",
  slot: `<div class="px-5 py-3 bg-gray-800 rounded">
      <h3 class="text-3xl text-white"> <i class="fas fa-spinner fa-spin"></i> LOADING...</h3>
    </div>`,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
